.full-width {
  width: 100%;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.mat-mdc-form-field-infix {
  display: flex;
}

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: white;
// }

.p-datatable {
  .p-datatable-header {
    padding: 0.75em 1.25em;
    background-color: #f5f5f5;
  }

  table.p-datatable-table {
    thead.p-datatable-thead {
      tr {
        th {
          &.col-center {
            text-align: center;
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      tr {
        line-height: 2.5em;

        td {
          padding: 0 1rem;

          &.col-center {
            text-align: center;
          }
        }
      }
    }
  }
}

search-form {
  mat-form-field.mat-mdc-form-field {
    margin: 0 0.5em;
  }
}

.p-panel {
  .p-panel-footer {
    button {
      margin-right: 0.5rem;
    }
  }
}
