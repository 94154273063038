/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@import 'variables';

$prefix: 't-';
@import '../node_modules/primeflex/primeflex';

@import '../node_modules/@angular/material/theming';


@import "~@fortawesome/fontawesome-free/css/all.css";

// @import '../node_modules/bootstrap/scss/bootstrap';
// @import '../node_modules/bootstrap/scss/bootstrap-utilities';

// Include non-theme styles for core.
@include mat.core();

// Define a theme.
$primary: mat.define-palette($mat-blue);
$accent:  mat.define-palette($mat-pink, A200, A100, A400);
$warn:    mat.define-palette($mat-red);

$theme:  mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
 ));

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

.alert-danger {
  color: var(--mdc-theme-error, #f44336);
}

@mixin border-radius($radius: $border-radius) {
    border-radius: $radius;
}

@mixin gradient-x($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
}

@mixin gradient-y($start-color: $gray-700, $end-color: $gray-800, $start-percent: null, $end-percent: null) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  .col-sm-12, .container-fluid {
      padding: 0;
  }
  @media (min-width: 1600px) {
    max-width: 1550px;
  }
}

th.p-col-button-1 {
    width: 4em;
}

body .ui-datatable .ui-datatable-data > tr > td {
    padding: 0 .75em;
}

body .ui-panel .ui-panel-titlebar {
    padding: 0.3em 1.0em;
}

formly-form > formly-field {
	width: 100%;
}

@import 'utils';
